import React from 'react';
import { Grid, Typography, Box, Link } from '@mui/material';
import Glass from '../components/core/Glass';
import EmailIcon from '@mui/icons-material/Email';
import ContactForm from '../components/forms/ContactForm';

const backgroundImgStyles = {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
}

const Contact = () => {
    return (
        <Grid container className="hero">
            <Grid item xs={12} md={6} sx={{p: 2}}>
                <Typography gutterBottom variant="h3">Contact</Typography>
                <Box sx={{display: 'flex', alignItems: 'center', m: 2}}>
                    <EmailIcon sx={{color: 'common.white', mr: 2, fontSize: 28}} />
                    <Link underline="none" color="common.white" href="mailto:info@alpha-returns.com.au">info@alpha-returns.com.au</Link>
                </Box>
                <Typography>Leave us a message and we will be back to you as soon as possible.</Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{background: 'linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(/assets/images/broken-hill-aerial-view.webp)', ...backgroundImgStyles}}>
                <Glass blur={7.5} styles={{m: 4}}>
                    <ContactForm />
                </Glass>            
            </Grid>
        </Grid>
    );
}

export default Contact;
