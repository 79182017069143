import React, { useState } from 'react';
import { InputBase, Box, Typography } from '@mui/material';
import propTypes from 'prop-types';
import { validate } from '../../helpers/validate';

const inputStyles = {
    bgcolor: 'rgba(255,255,255,0.3)',
    color: 'common.white',
    px: 2,
    borderRadius: 1
}

const Input = ({name, setFilled}) => {
    const id = name.toLowerCase();
    const [error, setError] = useState('');

    return (
        <>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Typography sx={{textTransform: 'uppercase', minWidth: 80, mr: 1}} component="label" htmlFor={id}>{name}</Typography>
                <label htmlFor={id}></label>                
                <InputBase 
                    fullWidth
                    inputProps={{sx: {...inputStyles, border: 2, borderColor: (error === '') ? 'rgba(0,0,0,0)' : 'error.main'}, name: id, id, onBlur: e => validate(e, id, setError, setFilled), onChange: () => (error === '') ? setFilled(prev => ({...prev, [id]: true})) : ''}}
                />            
            </Box>        
            <Typography sx={{my: 0, textTransform: 'uppercase', mb: (error === '') ? 2 : 1, fontWeight: 700}} align="right" variant="body2" color="error.main">{error}</Typography>
        </>
    );
}

Input.propTypes = {
    name: propTypes.string.isRequired,
    setFilled: propTypes.func.isRequired,
}

export default Input;
