import { createTheme } from '@mui/material';

export default createTheme({
    typography: {
        fontFamily: '"Arsenal", sans-serif',
        h1: {
            fontWeight: 700,
            textTransform: 'uppercase',
            letterSpacing: 5,
            fontFamily: 'Microgramma D Extended',
        },
        h2: {
            fontWeight: 700,
            textTransform: 'uppercase',
            letterSpacing: 5,
            fontFamily: 'Microgramma D Extended',
        },
        h3: {
            fontWeight: 700,
            textTransform: 'uppercase',
            letterSpacing: 5,
            fontFamily: 'Microgramma D Extended'
        },
        h4: {
            fontWeight: 700,
            textTransform: 'uppercase',
            letterSpacing: 5,
            fontFamily: 'Microgramma D Extended',
        },
        h5: {
            fontFamily: 'Microgramma D Extended',
            textTransform: 'uppercase',
            letterSpacing: 5,
        },
        h6: {
            fontFamily: 'Microgramma D Extended',
            textTransform: 'uppercase',
            letterSpacing: 5,
            fontSize: '0.75rem',
        },
        subtitle1: {
            fontSize: '1.25rem',
        },
        subtitle2: {
            fontSize: '1rem'
        },
        body1: {
            letterSpacing: 1.25
        },
        body2: {
            letterSpacing: 1.25
        },
    },
    palette: {
        primary: {
            main: '#ff0000'
        },
        secondary: {
            main: '#171717'
        },
        background: {
            default: '#272727'
        },
        text: {
            primary: '#fff',
        },
        silver: {
            primary: '#C0C0C0',
        },
        gold: {
            primary: '#FEDB41'
        },
        action: {
            disabled: '#fff',
            disabledBackground: 'rgba(255, 0, 0, 0.5)',
        }
    },
    overrides: {
        MuiTypography: {
            gutterBottom: {
                marginBottom: 100
            }
        }
    }
})