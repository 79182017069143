const val = {
    specialChars: /^[a-z'"-\s]+$/gi,
    email: /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/i,
    phone: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
    postcode:  /^[0-9]{4}$/,
}

export const validate = (e, name, setError, setFilled, optional = false) => {
    const setValid = () => {
        setError('');
        setFilled(prev => ({...prev, [name]: true}));
    }

    if(optional && e.target.value.trim() === '') return setValid();
    if(e.target.value.trim() === '' && !optional) return setError('This is a required field.'); 

    switch(name) {
        case 'name':
            return (!e.target.value.trim().match(val.specialChars)) ? setError('The name cannot include special characters or numbers.' ): setValid(); 
        case 'email':
            return (!e.target.value.trim().match(val.email)) ? setError('The email provided is invalid.') : setValid();
        case 'phone':
            return (!e.target.value.trim().match(val.phone)) ? setError('The phone number provided is invalid.') : setValid();
        case 'postcode':
            return (!e.target.value.trim().match(val.postcode)) ? setError('The postcode provided is invalid. It should be a 4-digit Australian postcode.') : setValid();
    }

    return setValid();
}