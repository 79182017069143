import { Stack, Grid, Typography, Box, Container, List, ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material';
import React from 'react';
import StyledButton from '../components/core/StyledButton';
import ArrowIcon from '../components/icons/ArrowIcon';
import Image from '../components/core/Image';   
import Glass from '../components/core/Glass';
import Ruler from '../components/core/Ruler';
import RegisterForm from '../components/forms/RegisterForm';

const backgroundImgStyles = {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
}

const Homepage = ({setTriggerAction}) => {
    return (
        <>
            <Grid container className="hero">
                <Grid item xs={12} md={6} sx={{p: 2}}>
                    <Typography sx={{textTransform: 'uppercase',}} gutterBottom variant="h3">Two-sided property business, transforming towns one home at a time</Typography>
                    <Typography gutterBottom>Harnessing the value-add potential of unloved properties to increase their market value, enabling home owners the ability to sell their property faster, easier and profitably.</Typography>
                    <Typography>Get started right now.</Typography>
                    <Box sx={{display: {xs: 'none', md: 'flex'}, justifyContent: 'right'}}>
                        <Image src="/assets/images/right-arrow.png" alt="right-arrow" width={120} styles={{mr: 8}} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{background: 'linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(/assets/images/broken-hill-aerial-view.webp)', ...backgroundImgStyles}}>
                    <Glass blur={7.5} styles={{m: 4}}>
                        <RegisterForm />
                    </Glass>
                </Grid>
            </Grid>
            <Box sx={{bgcolor: 'secondary.main', py: 4, mb: 4}}>
                <Grid container alignItems="center" justifyContent="center" sx={{mx: 'auto', maxWidth: 1200}}>
                    <Grid item xs={12} sm={6} md={5}>
                        <Box sx={{m: 4}}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <Image styles={{height: {xs: 150, md: 200}, width: {xs: 150, md: 200}}} alt="alpha returns before" exactSrc src="/assets/images/alpha-properties-before-1.jpg" />
                                <Image styles={{height: {xs: 150, md: 200}, width: {xs: 150, md: 200}}} alt="alpha returns after" src="/assets/images/alpha-properties-after-1.png" />
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <Image styles={{height: {xs: 150, md: 200}, width: {xs: 150, md: 200}}} alt="alpha returns before" src="/assets/images/alpha-properties-before-2.jpg" />
                                <Image styles={{height: {xs: 150, md: 200}, width: {xs: 150, md: 200}}} alt="alpha returns after" src="/assets/images/alpha-properties-after-2.jpg" />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={7} sx={{p: 2}}>
                        <Box sx={{display: 'flex', alignItems: 'center', mb: 4, flexWrap: 'wrap', justifyContent: 'center'}}>
                            <Typography align="center" variant="h4">What is</Typography>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Image src="/assets/images/alpha-text.png" alt="alpha-returns-text" width={170} styles={{ml: 1, position: 'relative', top: 2}} />
                                <Typography align="center" variant="h4">?</Typography>
                            </Box>
                        </Box>
                        <Typography align="center" gutterBottom>At the core, Alpha Returns is a property strategy group that connects real estate investors nationwide with properties that need to be sold quickly. It is a sophisticated solution that matches supply and demand in this particular niche of the property game.</Typography>
                        <Typography align="center" gutterBottom>Once acquired Alpha Returns employs best practices and industry-leading principles to quickly and affordably upgrade property, readying it for rental or for quick sale. This creates rental returns well above the market average and maximises value for our investor clients.</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{mb: 4, display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h2" sx={{mr: 2}}>1.</Typography>
                            <Typography variant="subtitle1">Research and identify under–developed townships and areas for exploration</Typography>
                        </Box>
                        <Box sx={{mb: 4, display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h2" sx={{mr: 2}}>2.</Typography>
                            <Typography variant="subtitle1">Research and identification of potentially profitable property opportunities</Typography>
                        </Box>                    
                        <Box sx={{mb: 4, display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h2" sx={{mr: 2}}>3.</Typography>
                            <Typography variant="subtitle1">Negotiate and purchase property and add to portfolio</Typography>
                        </Box>
                        <Box sx={{mb: 4, display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h2" sx={{mr: 2}}>4.</Typography>
                            <Typography variant="subtitle1">Refurbish and improve property to bring it up to market standard to maximise profitability</Typography>
                        </Box>
                        <Box sx={{mb: 4, display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h2" sx={{mr: 2}}>5.</Typography>
                            <Typography variant="subtitle1">Rent out and hold for long term, or flip for a capital gain</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={4} sx={{bgcolor: 'secondary.main', p: 2, mb: 4}}>
                            <Typography variant="h6">For Investors...</Typography>
                            <List>
                                <ListItem sx={{alignItems: 'flex-start'}}>
                                    <ListItemIcon sx={{mt: 1, minWidth: {xs: 28, md: 56}}}><ArrowIcon /></ListItemIcon>
                                    <ListItemText primary="Many unable to grow wealth with a secure investment vehicle as the entry point to real estate investing is escalating" />
                                </ListItem>
                                <ListItem sx={{alignItems: 'flex-start'}}>
                                    <ListItemIcon sx={{mt: 1, minWidth: {xs: 28, md: 56}}}><ArrowIcon /></ListItemIcon>
                                    <ListItemText primary="Need outside help to maximise value of property" />
                                </ListItem>
                            </List>
                            <Typography variant="h6">For Sellers...</Typography>
                            <List>
                                <ListItem sx={{alignItems: 'flex-start'}}>
                                    <ListItemIcon sx={{mt: 1, minWidth: {xs: 28, md: 56}}}><ArrowIcon /></ListItemIcon>
                                    <ListItemText primary="Helping home owners that need to sell their property very quickly but at a reasonable price" />
                                </ListItem>
                            </List>
                        </Paper>
                        <Image styles={{mb: 4, mx: 'auto'}} src="/assets/images/ascot-investors.jpg" alt="ascot investors" width={400} />
                    </Grid>
                </Grid>
            </Container>
            <Box sx={{bgcolor: 'secondary.main', mb: 4, p: 2}}>
                <Container>
                    <Box sx={{display: 'flex', alignItems: 'center', mb: 4, flexWrap: 'wrap', justifyContent: 'center'}}>
                        <Typography align="center" variant="h4">Why</Typography>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Image src="/assets/images/alpha-text.png" alt="alpha-returns-text" width={170} styles={{ml: 1, position: 'relative', top: 2}} />
                            <Typography align="center" variant="h4">?</Typography>
                        </Box>
                    </Box>
                    <Box sx={{display: {xs: 'block', md: 'flex'}, alignItems: 'center'}}>
                        <Image src="/assets/points/market-point.png" alt="market-point" width={200} styles={{mr: 4}} />
                        <Box>
                            <Typography variant="h5">Substantial and Attractive Market</Typography>
                            <List>
                                <ListItem sx={{alignItems: 'flex-start'}}>
                                    <ListItemIcon sx={{mt: 1, minWidth: {xs: 28, md: 56}}}><ArrowIcon /></ListItemIcon>
                                    <ListItemText primary="Approximetely 100 under-valued towns in Australia, with populations of 5000 - 1000" />
                                </ListItem>
                                <ListItem sx={{alignItems: 'flex-start'}}>
                                    <ListItemIcon sx={{mt: 1, minWidth: {xs: 28, md: 56}}}><ArrowIcon /></ListItemIcon>
                                    <ListItemText primary="Over 613,000 potential sellers identified just across the eastern seaboard" />
                                </ListItem>
                                <ListItem sx={{alignItems: 'flex-start'}}>
                                    <ListItemIcon sx={{mt: 1, minWidth: {xs: 28, md: 56}}}><ArrowIcon /></ListItemIcon>
                                    <ListItemText primary="Reaching a small proportion of this creates a large opportunity lasting years" />
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                    <Ruler vertical height={60} offsetLeft={100} />
                    <Box sx={{display: {xs: 'block', md: 'flex'}, alignItems: 'center'}}>
                        <Image src="/assets/points/team-point.png" alt="team-point" width={200} styles={{mr: 4}} />
                        <Box>
                            <Typography variant="h5">High Quality Management Team</Typography>
                            <List>
                                <ListItem sx={{alignItems: 'flex-start'}}>
                                    <ListItemIcon sx={{mt: 1, minWidth: {xs: 28, md: 56}}}><ArrowIcon /></ListItemIcon>
                                    <ListItemText primary="20+ years experience acrosss property enhancement and consulting" />
                                </ListItem>
                                <ListItem sx={{alignItems: 'flex-start'}}>
                                    <ListItemIcon sx={{mt: 1, minWidth: {xs: 28, md: 56}}}><ArrowIcon /></ListItemIcon>
                                    <ListItemText primary="25+ years in the construction industry including acquiring and improving properties" />
                                </ListItem>
                                <ListItem sx={{alignItems: 'flex-start'}}>
                                    <ListItemIcon sx={{mt: 1, minWidth: {xs: 28, md: 56}}}><ArrowIcon /></ListItemIcon>
                                    <ListItemText primary="Specialists in rapid acquisition, through quick data-backed decision-making model " />
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                    <Ruler vertical height={60} offsetLeft={100} />
                    <Box sx={{display: {xs: 'block', md: 'flex'}, alignItems: 'center'}}>
                        <Image src="/assets/points/product-point.png" alt="product-point" width={200} styles={{mr: 4}} />
                        <Box>
                            <Typography variant="h5">Outstanding Product</Typography>
                            <List>
                                <ListItem sx={{alignItems: 'flex-start'}}>
                                    <ListItemIcon sx={{mt: 1, minWidth: {xs: 28, md: 56}}}><ArrowIcon /></ListItemIcon>
                                    <ListItemText primary="Tailored solution for sellers and well-considered risk-managed investment opportunities" />
                                </ListItem>
                                <ListItem sx={{alignItems: 'flex-start'}}>
                                    <ListItemIcon sx={{mt: 1, minWidth: {xs: 28, md: 56}}}><ArrowIcon /></ListItemIcon>
                                    <ListItemText primary="Creates multiple income streams for investors" />
                                </ListItem>
                                <ListItem sx={{alignItems: 'flex-start'}}>
                                    <ListItemIcon sx={{mt: 1, minWidth: {xs: 28, md: 56}}}><ArrowIcon /></ListItemIcon>
                                    <ListItemText primary="Ability to create significant alpha in a secure market" />
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                    <Ruler vertical height={60} offsetLeft={100} />
                    <Box sx={{display: {xs: 'block', md: 'flex'}, alignItems: 'center'}}>
                        <Image src="/assets/points/advantage-point.png" alt="advantage-point" width={200} styles={{mr: 4}} />
                        <Box>
                            <Typography variant="h5">Strong Intrinsic Competitive Advantage</Typography>
                            <List>
                                <ListItem sx={{alignItems: 'flex-start'}}>
                                    <ListItemIcon sx={{mt: 1, minWidth: {xs: 28, md: 56}}}><ArrowIcon /></ListItemIcon>
                                    <ListItemText primary="Accurately identifying underperforming, pre-boom markets" />
                                </ListItem>
                                <ListItem sx={{alignItems: 'flex-start'}}>
                                    <ListItemIcon sx={{mt: 1, minWidth: {xs: 28, md: 56}}}><ArrowIcon /></ListItemIcon>
                                    <ListItemText primary="Investing in a series of these in a conveyor-belt system, buying refurbishments in bulk" />
                                </ListItem>
                                <ListItem sx={{alignItems: 'flex-start'}}>
                                    <ListItemIcon sx={{mt: 1, minWidth: {xs: 28, md: 56}}}><ArrowIcon /></ListItemIcon>
                                    <ListItemText primary="Creating cashflow and capital growth for investors" />
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                    <Ruler vertical height={60} offsetLeft={100} />
                    <Box sx={{display: {xs: 'block', md: 'flex'}, alignItems: 'center'}}>
                        <Image src="/assets/points/growth-point.png" alt="growth-point" width={200} styles={{mr: 4}} />
                        <Box>
                            <Typography variant="h5">Attractive Unit Metrics & Growth Pipeline</Typography>
                            <List>
                                <ListItem sx={{alignItems: 'flex-start'}}>
                                    <ListItemIcon sx={{mt: 1, minWidth: {xs: 28, md: 56}}}><ArrowIcon /></ListItemIcon>
                                    <ListItemText primary="Each individual project has significant capital growth opportunity buying below market value" />
                                </ListItem>
                                <ListItem sx={{alignItems: 'flex-start'}}>
                                    <ListItemIcon sx={{mt: 1, minWidth: {xs: 28, md: 56}}}><ArrowIcon /></ListItemIcon>
                                    <ListItemText primary="Systemised approach improves the buying power for parts and labour" />
                                </ListItem>
                                <ListItem sx={{alignItems: 'flex-start'}}>
                                    <ListItemIcon sx={{mt: 1, minWidth: {xs: 28, md: 56}}}><ArrowIcon /></ListItemIcon>
                                    <ListItemText primary="Current pipeline contains 25+ properties ripe for value enhancement" />
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Container>
                <Typography gutterBottom align="center" sx={{color: 'gold.primary', fontFamily: 'Microgramma D Extended'}} variant="h3"><span style={{marginRight: 16}}>Get</span> Started.</Typography>
                <Stack sx={{mb: 4}} spacing={2} direction="row" justifyContent="center">
                    <StyledButton setTriggerAction={setTriggerAction}>Register Interest</StyledButton>
                    <StyledButton setTriggerAction={setTriggerAction} href="/the-proof" primary={false}>The Proof</StyledButton>
                </Stack>
            </Container>
        </>
    );
}

export default Homepage;
