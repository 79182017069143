import api from './api';

const formatFields = (fields) => {
    const formData = new FormData();

    Object.entries(fields).forEach(([name, value]) => formData.append(name, value));

    return formData;
}

export const submitRegister = async fields => {
    try {
        const res = await api.post(`/add`, formatFields(fields));
    
        return res;
    } catch(err) {
        console.error(`AXIOS ERROR - ${err}`);
        
        return false;
    }
}

export const submitContact = async fields => {
    try {
        const res = await api.post(`/enquiry`, formatFields(fields));
    
        return res;
    } catch(err) {
        console.error(`AXIOS ERROR - ${err}`);
        
        return false;
    }
}