import React, { useState } from 'react';

import Header from './Header';
import Footer from './Footer';

import Homepage from '../pages/Homepage';
import About from '../pages/About';
import TheProof from '../pages/TheProof';
import Contact from '../pages/Contact';
import NotFound from '../pages/NotFound';

import { Routes, Route } from 'react-router-dom';
import SignUpModal from './forms/SignUpModal';

const App = () => {
    const [triggerAction, setTriggerAction] = useState(false);

    return (
        <>
            <Header setTriggerAction={setTriggerAction} />
            <Routes>
                <Route path="/about" element={<About />} />
                <Route path="/the-proof" element={<TheProof />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/" element={<Homepage setTriggerAction={setTriggerAction} />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
            <SignUpModal trigger={triggerAction} setTriggerAction={setTriggerAction} />
        </>
    );
}

export default App;
