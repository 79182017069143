import { Modal, Stack, Box, Typography, IconButton, Alert } from '@mui/material';
import propTypes from 'prop-types';
import Input from './Input';
import StyledButton from '../core/StyledButton';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { submitRegister } from '../../helpers/submit';

const modalStyles = {
    position: 'absolute',
    top: {xs: 0, md: '50%'},
    left: {xs: 0, md: '50%'},
    right: {xs: 0, md: ''},
    transform: {xs: 'none', md: 'translate(-50%, -50%)'},
    bgcolor: 'secondary.main',
    p: 4,
    maxWidth: {md: 550}, 
}

const SignUpModal = ({trigger, setTriggerAction}) => {
    const [filled, setFilled] = useState({
        name: false,
        email: false,
        postcode: false,
    });

    const [alertText, setAlertText] = useState(false);
    const [formError, setFormError] = useState(false);
    const [loading, setLoading] = useState(false);

    const submit = async e => {
        e.preventDefault();
        setLoading(true);

        const [name, phone, email, postcode] = e.target.elements;
            
        const fields = {
            name: name.value,
            phone: phone.value,
            email: email.value,
            postcode: postcode.value,
        };

        if(Object.values(filled).includes(false)) {
            // Error 
            setFormError(true);
            setAlertText('There was an issue with the data entered into the form. Please review the fields and try again.');
        } else {
            const res = await submitRegister(fields);
            setLoading(false);

            if(res.status == 200) {
                setFormError(false);
                setAlertText('Thanks for registering your interest, we will contact you shortly!');
            } else {
                setFormError(true);
                setAlertText('There has been an error processing your request, please try again later, or contact us via email.');
            }
        }
    }

    return (
        <Modal open={trigger} onClose={() => setTriggerAction(false)}>
            <Box sx={modalStyles}>
                <Box sx={{position: 'relative'}}>
                    <CloseIcon sx={{color: '#9e9e9e', cursor: 'pointer', position: 'absolute', top: -24, right: -24}} onClick={() => setTriggerAction(false)} />
                    <Typography variant="h5" gutterBottom align="center">Register Your Interest</Typography>
                    <form onSubmit={e => submit(e)}>
                        <Stack>
                            <Input setFilled={setFilled} name="Name" />
                            <Input setFilled={setFilled} name="Phone" />
                            <Input setFilled={setFilled} name="Email" />
                            <Input setFilled={setFilled} name="Postcode" />
                        </Stack>
                        <StyledButton loading={loading} setTriggerAction={false} styles={{mt: 4}} submit>{(loading) ? 'Loading...' : 'Register Interest'}</StyledButton>
                        <Alert sx={{my: 2, display: (!alertText) ? 'none' : 'flex'}} severity={(formError) ? 'error' : 'success'} children={alertText} />
                    </form>   
                </Box>
            </Box>
        </Modal>
    );
}

SignUpModal.propTypes = {
    trigger: propTypes.bool.isRequired,
    setTriggerAction: propTypes.func.isRequired,
}

export default SignUpModal;
