import React, { useState } from 'react';
import { Drawer, AppBar, Toolbar, Box, Link, IconButton, List, ListItemText, ListItem, ListItemButton } from '@mui/material';
import StyledButton from './core/StyledButton';
import Image from './core/Image';
import MenuIcon from '@mui/icons-material/Menu';

const Header = ({setTriggerAction}) => {
    const [openMenu, setOpenMenu] = useState(false);

    return (
        <>
            <AppBar color="secondary" position="static" sx={{boxShadow: 0, py: {xs: 1, md: 2}}}>
                <Toolbar>
                    <IconButton aria-label="Mobile Menu" color="inherit" sx={{display: {xs: 'flex', md: 'none'}, mr: 4}} onClick={() => setOpenMenu(prev => !prev)}>
                        <MenuIcon />
                    </IconButton>
                    <a href="/" style={{display: 'flex', alignItems: 'center'}}>
                        <Image src="/assets/images/alpha-returns-logo-cropped.png" alt="alpha-returns-logo" width={150} styles={{mr: 4}} />    
                    </a>
                    <Box sx={{alignItems: 'center', display: {xs: 'none', md: 'flex'}}}>
                        <Link color="common.white" underline="none" sx={{mr: 2, textTransform: 'uppercase'}} href="/">Home</Link>
                        <Link color="common.white" underline="none" sx={{mr: 2, textTransform: 'uppercase'}} href="/the-proof">The Proof</Link>
                        <Link color="common.white" underline="none" sx={{mr: 2, textTransform: 'uppercase'}} href="/about">About Us</Link>
                        <Link color="common.white" underline="none" sx={{mr: 2, textTransform: 'uppercase'}} href="/contact">Contact</Link>
                    </Box>
                    <Box sx={{flexGrow: 1}}></Box>
                    <StyledButton setTriggerAction={setTriggerAction} styles={{display: {xs: 'none', md: 'flex'}}}>Register Interest</StyledButton>
                </Toolbar>
            </AppBar>
            <Drawer open={openMenu} onClose={() => setOpenMenu(false)} PaperProps={{sx: {bgcolor: 'secondary.main', width: 230, height: 'fit-content'}}}>
                <List>
                    <ListItem>
                        <ListItemButton component="a" href="/">
                            <ListItemText>HOME</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton component="a" href="/the-proof">
                            <ListItemText>THE PROOF</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton component="a" href="/about">
                            <ListItemText>ABOUT US</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton component="a" href="/contact">
                            <ListItemText>CONTACT</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <StyledButton setTriggerAction={setTriggerAction} styles={{mx: 2, mb: 2}}>Register Interest</StyledButton>
                    </ListItem>
                </List>
            </Drawer>
        </>
    );
}

export default Header;
