import React from 'react';
import { Container, Typography, Grid, Box, Avatar, Link, Paper } from '@mui/material';
import Image from '../components/core/Image';

const About = () => {
    return (
        <Container sx={{mt: 4}}>
            <Typography gutterBottom variant="h3">Meet the founders</Typography>
            <Typography gutterBottom sx={{mb: 4}}>Combining experience, financial analysis, technologies and due diligence with empathy and creativity as the basis for our client focused solutions.</Typography>
            <Grid container spacing={4} sx={{mb: 4}}>
                <Grid item xs={12} md={6}>
                    <Paper elevation={4} sx={{bgcolor: 'secondary.main', p: 4, height: {xs: 'auto', md: 420, xl: 380}, position: 'relative'}}>
                        <Box sx={{display: 'flex', alignItems: 'center', mb: 4}}>
                            <Avatar sx={{height: 88, width: 88, mr: 2}} imgProps={{sx: {objectPosition: 'top'}}} alt="Daniel Hedgcock" src="/assets/images/daniel-hedgcock.webp" />
                            <Box>
                                <Typography variant="h6">Daniel Hedgcock</Typography>
                                <Typography sx={{fontStyle: 'italic', color: 'silver.primary', fontWeight: 700}}>Co-Founder</Typography>
                            </Box>
                        </Box>
                        <Typography sx={{mb: {xs: 8, md: 4}, pt: 1}}>Daniel has over 25 years combined in the residential construction and professional renovation industries across all areas. His experience has seen him identify, and invest in underperforming, high yielding markets before they boom. As an accredited mindset coach, Daniel understands what's needed to move you, the investor, from being average to being alpha.</Typography>
                        {/* <Link sx={{display: 'flex', alignItems: 'center', position: 'absolute', left: 32, bottom: 40}} underline="none" color="inherit" href="https://linkedin.com" target="_blank" rel="noreferrer noopener">
                            <Image src="/assets/images/linkedin.png" alt="linkedin-logo" width={24} />
                            <Typography variant="body2" sx={{ml: 2}}>Linkedin Profile</Typography>
                        </Link> */}
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper elevation={4} sx={{bgcolor: 'secondary.main', p: 4, height: {xs: 'auto', md: 420, xl: 380}, position: 'relative'}}>
                        <Box sx={{display: 'flex', alignItems: 'center', mb: 4}}>
                            <Avatar sx={{height: 88, width: 88, mr: 2}} alt="James Monaghan" src="/assets/images/james-monaghan.webp" />
                            <Box>
                                <Typography variant="h6">James Monaghan</Typography>
                                <Typography sx={{fontStyle: 'italic', color: 'silver.primary', fontWeight: 700}}>Co-Founder</Typography>
                            </Box>
                        </Box>
                        <Typography sx={{mb: {xs: 8, md: 4}}}>James specialises in creative acquisition strategies, and has personally bought over 100 houses across every state and territory in Australia since 2008. He holds Diplomas in Real Estate, Finance-Mortgage Broker, and Building & Construction-Management. His experience in debt negotiation and reduction, allows him to work towards finding the best solution for all.</Typography>
                        {/* <Link sx={{display: 'flex', alignItems: 'center', position: 'absolute', left: 32, bottom: 40}} underline="none" color="inherit" href="https://linkedin.com" target="_blank" rel="noreferrer noopener">
                            <Image src="/assets/images/linkedin.png" alt="linkedin-logo" width={24} />
                            <Typography variant="body2" sx={{ml: 2}}>Linkedin Profile</Typography>
                        </Link> */}
                    </Paper>                       
                </Grid>    
            </Grid>
        </Container>
    );
}

export default About;
