import React, { useState } from 'react';
import Input from './Input';
import { Stack, Alert, Typography } from '@mui/material';
import StyledButton from '../core/StyledButton';
import { submitRegister } from '../../helpers/submit';

const RegisterForm = () => {
    const [filled, setFilled] = useState({
        name: false,
        email: false,
        postcode: false,
    });

    const [alertText, setAlertText] = useState(false);
    const [formError, setFormError] = useState(false);
    const [loading, setLoading] = useState(false);

    const submit = async e => {
        e.preventDefault();
        setLoading(true);

        const [name, phone, email, postcode] = e.target.elements;
            
        const fields = {
            name: name.value,
            phone: phone.value,
            email: email.value,
            postcode: postcode.value,
        };

        if(Object.values(filled).includes(false)) {
            // Error 
            setFormError(true);
            setAlertText('There was an issue with the data entered into the form. Please review the fields and try again.');
        } else {
            const res = await submitRegister(fields);

            setLoading(false);
            console.log(res);

            if(res.status == 200) {
                setFormError(false);
                setAlertText('Thanks for registering your interest, we will contact you shortly!');
            } else {
                setFormError(true);
                setAlertText('There has been an error processing your request, please try again later, or contact us via email.');
            }
        }
    }

    return (
        <form name="register" onSubmit={e => submit(e)}>
            <Typography variant="h5" align="center" sx={{mb: 2}} children="Register Your Interest" />
            <Stack spacing={2}> 
                <Input setFilled={setFilled} name="Name" />
                <Input setFilled={setFilled} name="Phone" />
                <Input setFilled={setFilled} name="Email" />
                <Input setFilled={setFilled} name="Postcode" />
            </Stack>
            <input type="hidden" name="form-name" value="register" />
            <StyledButton loading={loading} setTriggerAction={false} styles={{mt: 2}} submit>{(loading) ? 'Loading...' : 'Register Interest'}</StyledButton>
            <Alert sx={{my: 2, display: (!alertText) ? 'none' : 'flex'}} severity={(formError) ? 'error' : 'success'} children={alertText} />
        </form>            
    );
}

export default RegisterForm;
