import React from 'react';
import { Container, Typography, Button } from '@mui/material';

const NotFound = () => {
    return (
        <Container sx={{mt: 4, height: 'calc(100vh - 380px)'}}>
            <Typography gutterBottom align="center" variant="h3">Page Not Found...</Typography>
            <Typography gutterBottom align="center">The page you are looking for cannot be found.</Typography>
            <Button variant="contained" href="/" sx={{mx: 'auto', my: 2, display: 'block', width: 'fit-content'}}>Back to Homepage</Button>
        </Container>
    );
}

export default NotFound;
