import React from 'react';
import { styled } from '@mui/material';
import propTypes from 'prop-types';

const Default = styled('img')({
    width: 'auto',
    maxWidth: '100%',
    height: 'auto',
    display: 'block',
    objectFit: 'cover'
});

const Image = ({src, alt, width, styles, exactSrc}) => {
 
    const srcToWebp = src.replace(/png|jpeg|jpg/g, 'webp');
    
    return <Default sx={{maxWidth: (width) ? width : 'auto', ...styles}} src={(exactSrc) ? src : srcToWebp} alt={alt} />
}

Image.propTypes = {
    src: propTypes.string.isRequired,
    alt: propTypes.string.isRequired,
    width: propTypes.number,
    styles: propTypes.object,
}

export default Image;
