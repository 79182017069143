import React from 'react';
import { render } from 'react-dom';
import App from './components/App';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './theme';

import { BrowserRouter } from 'react-router-dom';

import './App.css';
import '@tsamantanis/react-glassmorphism/dist/index.css'

// Add Responsive Text to Theme Before Using
theme.typography.h3 = {
    fontSize: '3rem',
    [theme.breakpoints.down('md')]: {
        fontSize: '2.5rem'
    }
}

render(
    <React.StrictMode>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <App />
            </ThemeProvider>        
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);