import { Grid, Typography, Box, Paper, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import Glass from '../components/core/Glass';
import Image from '../components/core/Image';
import Ruler from '../components/core/Ruler';
import RegisterForm from '../components/forms/RegisterForm';

const backgroundImgStyles = {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
}

const TheProof = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <Grid container sx={{mb: 4}} className="hero">
                <Grid item xs={12} md={6} sx={{p: 2}}>
                    <Typography gutterBottom variant="h3">The proof is right here...</Typography>
                    <Typography gutterBottom>Having been in the residential construction industry for over 25 years, and having been professional renovators for the last eight years and bought over 100 houses in every state and territory in Australia since 2008, together we realised an opportunity existed to create wealth for both homeowners and property investors in forgotten and under-appreciated markets, often ignored by most experienced investors.</Typography>
                    <Typography gutterBottom>We have personally experienced the highs and lows of property investment, including one of us losing our home to the banks and have learned numerous lessons along the way. These personal experiences allow us to lead and grow this business with empathy and understanding. We are driven by the goal of creating spaces for millions of memories to be made.</Typography>
                    <Typography gutterBottom>We look forward to you joining us on this journey to provide financial freedom for you as a property investor.</Typography>
                    <Typography variant="h5">See for yourself, the results of our work...</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{backgroundImage: `url(/assets/images/broken-hill-aerial-view.webp)`, ...backgroundImgStyles}}>
                    <Glass blur={7.5} styles={{m: 4}}>
                        <RegisterForm />
                    </Glass>
                </Grid>
            </Grid>
            {/* Property */}
            <Box>
                <Box sx={{display: {xs: 'block', md: 'flex'}, alignItems: 'center', mx: {xs: 2, lg: 'auto'}, justifyContent: {xs: 'center', md: 'space-between', lg: 'center'}, maxWidth: 1200}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Image styles={{ml: {xs: 2, md: 0}}} src="/assets/images/location-icon.png" alt="location-icon" width={120} />
                        <Box>
                            <Typography variant="h6" sx={{fontSize: '1rem', mb: 1, whiteSpace: {xs: 'normal', lg: 'nowrap'}}}>Norlane, VIC</Typography>
                            <Typography sx={{whiteSpace: {xs: 'normal', md: 'nowrap'}}}>3 bed, 1 bath, 1 car</Typography>
                        </Box>
                    </Box>
                    {(isMobile) ? <Ruler vertical height={30} offsetLeft={75} /> : <Ruler width={200} offsetLeft={18} styles={{flexGrow: 1, display: {md: 'none', lg: 'flex'}}} />}
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Image src="/assets/images/equity-icon.png" alt="equity-icon" width={150} />
                        <Box sx={{mr: {xs: 0, lg: 2}}}>
                            <Typography variant="h5" sx={{fontSize: '1rem', mb: 1, whiteSpace: {xs: 'normal', md: 'nowrap'}}}>Equity Created</Typography>
                            <Typography variant="h3" sx={{whiteSpace: {xs: 'normal', md: 'nowrap'}, color: 'gold.primary'}}>$115,000</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Grid container sx={{mx: 'auto', maxWidth: 1200}}>
                <Grid item xs={12} md={7}>
                    <Typography gutterBottom align="center" variant="h4">Before...</Typography>
                    <Box sx={{m: {xs: 0, md: 3}}}>
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <Image styles={{height: {xs: 150, sm: 200, lg: 300}, width: {xs: 150, sm: 200, lg: 300}}} alt="alpha returns investment improvment" src="/assets/images/norlane-before-house.jpg" />
                            <Image styles={{height: {xs: 150, sm: 200, lg: 300}, width: {xs: 150, sm: 200, lg: 300}}} alt="alpha returns investment improvment" src="/assets/images/norlane-before-kitchen.jpg" />                        
                        </Box>
                    </Box>
                    <Typography gutterBottom sx={{mt: 2}} align="center" variant="h4">After...</Typography>
                    <Box sx={{m: {xs: 0, md: 3}}}>
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <Image styles={{height: {xs: 150, sm: 200, lg: 300}, width: {xs: 150, sm: 200, lg: 300}}} alt="alpha returns investment improvment" src="/assets/images/norlane-after-house.jpg" />
                            <Image styles={{height: {xs: 150, sm: 200, lg: 300}, width: {xs: 150, sm: 200, lg: 300}}} alt="alpha returns investment improvment" src="/assets/images/norlane-after-kitchen.jpg" />                        
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={5} sx={{p: {xs: 2, md: 1}}}>
                    <Paper elevation={4} sx={{bgcolor: 'secondary.main', width: {xs: 'fit-content%', md: 'fit-content'}, p: 4, mx: 'auto', mt: 2}}>
                        <Typography variant="h5" gutterBottom>Quick Stats</Typography>
                        <Box sx={{display: 'flex', flexDirection: {xs: 'row', md: 'column'}, alignItems: 'flex-start', flexWrap: 'wrap'}}>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Image width={75} src="/assets/images/price-icon.png" alt="price-icon" />
                                <Box>
                                    <Typography variant="h6">Buy Price</Typography>
                                    <Typography sx={{ml: 1}}>$195,000</Typography>
                                </Box>
                            </Box>
                            <Ruler offsetLeft={35} vertical height={20} styles={{display: {xs: 'none', md: 'block'}}} />
                            <Box sx={{display: 'flex', alignItems: 'center'}}>

                                <Image width={75} src="/assets/images/value-icon.png" alt="value-icon"  />
                                <Box>
                                    <Typography variant="h6">Market Value</Typography>
                                    <Typography sx={{ml: 1}}>$225,000</Typography>
                                </Box>
                            </Box>
                            <Ruler offsetLeft={35} vertical height={20} styles={{display: {xs: 'none', md: 'block'}}} />
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Image width={75} src="/assets/images/renovation-icon.png" alt="renovation-icon" />
                                <Box>
                                    <Typography variant="h6">Reno Cost</Typography>
                                    <Typography sx={{ml: 1}}>$110,000</Typography>
                                </Box>
                            </Box>
                            <Ruler offsetLeft={35} vertical height={20} styles={{display: {xs: 'none', md: 'block'}}} />
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Image width={75} src="/assets/images/money-icon.png" alt="money-icon" />
                                <Box>
                                    <Typography variant="h6">Improved Value</Typography>
                                    <Typography sx={{ml: 1}}>$420,000</Typography>
                                </Box>
                            </Box>
                            <Ruler offsetLeft={35} vertical height={20} styles={{display: {xs: 'none', md: 'block'}}} />
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Image width={75} src="/assets/images/timeframe-icon.png" alt="timeframe-icon" />
                                <Box>
                                    <Typography variant="h6">Timeframe</Typography>
                                    <Typography sx={{ml: 1}}>12 Weeks</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            {/* Property */}
            <Box>
                <Box sx={{display: {xs: 'block', md: 'flex'}, alignItems: 'center', mx: {xs: 2, lg: 'auto'}, justifyContent: {xs: 'center', md: 'space-between', lg: 'center'}, maxWidth: 1200}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Image styles={{ml: {xs: 2, md: 0}}} src="/assets/images/location-icon.png" alt="location-icon" width={120} />
                        <Box>
                            <Typography variant="h6" sx={{fontSize: '1rem', mb: 1, whiteSpace: {xs: 'normal', lg: 'nowrap'}}}>Northern Suburbs Adelaide, SA</Typography>
                            <Typography sx={{whiteSpace: {xs: 'normal', md: 'nowrap'}}}>4 bed, 2 bath, 2 car, pool</Typography>
                        </Box>
                    </Box>
                    {(isMobile) ? <Ruler vertical height={30} offsetLeft={75} /> : <Ruler width={200} offsetLeft={18} styles={{flexGrow: 1, display: {md: 'none', lg: 'flex'}}} />}
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Image src="/assets/images/equity-icon.png" alt="equity-icon" width={150} />
                        <Box sx={{mr: {xs: 0, lg: 2}}}>
                            <Typography variant="h5" sx={{fontSize: '1rem', mb: 1, whiteSpace: {xs: 'normal', md: 'nowrap'}}}>Equity Created</Typography>
                            <Typography variant="h3" sx={{whiteSpace: {xs: 'normal', md: 'nowrap'}, color: 'gold.primary'}}>$85,000</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Grid container sx={{mx: 'auto', maxWidth: 1200}}>
                <Grid item xs={12} md={7}>
                    <Typography gutterBottom align="center" variant="h4">Before...</Typography>
                    <Box sx={{m: {xs: 0, md: 3}}}>
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <Image styles={{height: {xs: 150, sm: 200, lg: 300}, width: {xs: 150, sm: 200, lg: 300}}} alt="alpha returns investment improvment" src="/assets/images/adelaide-before-house.jpg" />
                            <Image styles={{height: {xs: 150, sm: 200, lg: 300}, width: {xs: 150, sm: 200, lg: 300}}} alt="alpha returns investment improvment" src="/assets/images/adelaide-before-backyard.jpg" />                        
                        </Box>
                    </Box>
                    <Typography gutterBottom sx={{mt: 2}} align="center" variant="h4">After...</Typography>
                    <Box sx={{m: {xs: 0, md: 3}}}>
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <Image styles={{height: {xs: 150, sm: 200, lg: 300}, width: {xs: 150, sm: 200, lg: 300}}} alt="alpha returns investment improvment" src="/assets/images/adelaide-after-house.jpg" />
                            <Image styles={{height: {xs: 150, sm: 200, lg: 300}, width: {xs: 150, sm: 200, lg: 300}}} alt="alpha returns investment improvment" src="/assets/images/adelaide-after-backyard.jpg" />                        
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={5} sx={{p: {xs: 2, md: 1}}}>
                    <Paper elevation={4} sx={{bgcolor: 'secondary.main', width: {xs: 'fit-content%', md: 'fit-content'}, p: 4, mx: 'auto', mt: 2}}>
                        <Typography variant="h5" gutterBottom>Quick Stats</Typography>
                        <Box sx={{display: 'flex', flexDirection: {xs: 'row', md: 'column'}, alignItems: 'flex-start', flexWrap: 'wrap'}}>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Image width={75} src="/assets/images/price-icon.png" alt="price-icon" />
                                <Box>
                                    <Typography variant="h6">Buy Price</Typography>
                                    <Typography sx={{ml: 1}}>$145,000</Typography>
                                </Box>
                            </Box>
                            <Ruler offsetLeft={35} vertical height={20} styles={{display: {xs: 'none', md: 'block'}}} />
                            <Box sx={{display: 'flex', alignItems: 'center'}}>

                                <Image width={75} src="/assets/images/value-icon.png" alt="value-icon"  />
                                <Box>
                                    <Typography variant="h6">Market Value</Typography>
                                    <Typography sx={{ml: 1}}>$155,000</Typography>
                                </Box>
                            </Box>
                            <Ruler offsetLeft={35} vertical height={20} styles={{display: {xs: 'none', md: 'block'}}} />
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Image width={75} src="/assets/images/renovation-icon.png" alt="renovation-icon" />
                                <Box>
                                    <Typography variant="h6">Reno Cost</Typography>
                                    <Typography sx={{ml: 1}}>$120,000</Typography>
                                </Box>
                            </Box>
                            <Ruler offsetLeft={35} vertical height={20} styles={{display: {xs: 'none', md: 'block'}}} />
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Image width={75} src="/assets/images/money-icon.png" alt="money-icon" />
                                <Box>
                                    <Typography variant="h6">Improved Value</Typography>
                                    <Typography sx={{ml: 1}}>$350,000</Typography>
                                </Box>
                            </Box>
                            <Ruler offsetLeft={35} vertical height={20} styles={{display: {xs: 'none', md: 'block'}}} />
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Image width={75} src="/assets/images/timeframe-icon.png" alt="timeframe-icon" />
                                <Box>
                                    <Typography variant="h6">Timeframe</Typography>
                                    <Typography sx={{ml: 1}}>13 Weeks</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            {/* Property */}
            <Box>
                <Box sx={{display: {xs: 'block', md: 'flex'}, alignItems: 'center', mx: {xs: 2, lg: 'auto'}, justifyContent: {xs: 'center', md: 'space-between', lg: 'center'}, maxWidth: 1200}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Image styles={{ml: {xs: 2, md: 0}}} src="/assets/images/location-icon.png" alt="location-icon" width={120} />
                        <Box>
                            <Typography variant="h6" sx={{fontSize: '1rem', mb: 1, whiteSpace: {xs: 'normal', lg: 'nowrap'}}}>Ballarat, VIC</Typography>
                            <Typography sx={{whiteSpace: {xs: 'normal', md: 'nowrap'}}}>3 bed, 2 bath</Typography>
                        </Box>
                    </Box>
                    {(isMobile) ? <Ruler vertical height={30} offsetLeft={75} /> : <Ruler width={200} offsetLeft={18} styles={{flexGrow: 1, display: {md: 'none', lg: 'flex'}}} />}
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Image src="/assets/images/equity-icon.png" alt="equity-icon" width={150} />
                        <Box sx={{mr: {xs: 0, lg: 2}}}>
                            <Typography variant="h5" sx={{fontSize: '1rem', mb: 1, whiteSpace: {xs: 'normal', md: 'nowrap'}}}>Equity Created</Typography>
                            <Typography variant="h3" sx={{whiteSpace: {xs: 'normal', md: 'nowrap'}, color: 'gold.primary'}}>$208,000</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Grid container sx={{mx: 'auto', maxWidth: 1200}}>
                <Grid item xs={12} md={7}>
                    <Typography gutterBottom align="center" variant="h4">Before...</Typography>
                    <Box sx={{m: {xs: 0, md: 3}}}>
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <Image styles={{height: {xs: 150, sm: 200, lg: 300}, width: {xs: 150, sm: 200, lg: 300}}} alt="alpha returns investment improvment" src="/assets/images/ballarat-before-bath.jpg" />
                            <Image styles={{height: {xs: 150, sm: 200, lg: 300}, width: {xs: 150, sm: 200, lg: 300}, minWidth: {xs: 150, md: 200, lg: 300}}} alt="alpha returns investment improvment" src="/assets/images/ballarat-before-kitchen.jpg" />                        
                        </Box>
                    </Box>
                    <Typography gutterBottom sx={{mt: 2}} align="center" variant="h4">After...</Typography>
                    <Box sx={{m: {xs: 0, md: 3}}}>
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <Image styles={{height: {xs: 150, sm: 200, lg: 300}, width: {xs: 150, sm: 200, lg: 300}}} alt="alpha returns investment improvment" src="/assets/images/ballarat-after-bath.jpg" />
                            <Image styles={{height: {xs: 150, sm: 200, lg: 300}, width: {xs: 150, sm: 200, lg: 300}}} alt="alpha returns investment improvment" src="/assets/images/ballarat-after-kitchen.jpg" />                        
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={5} sx={{p: {xs: 2, md: 1}}}>
                    <Paper elevation={4} sx={{bgcolor: 'secondary.main', width: {xs: 'fit-content%', md: 'fit-content'}, p: 4, mx: 'auto', mt: 2}}>
                        <Typography variant="h5" gutterBottom>Quick Stats</Typography>
                        <Box sx={{display: 'flex', flexDirection: {xs: 'row', md: 'column'}, alignItems: 'flex-start', flexWrap: 'wrap'}}>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Image width={75} src="/assets/images/price-icon.png" alt="price-icon" />
                                <Box>
                                    <Typography variant="h6">Buy Price</Typography>
                                    <Typography sx={{ml: 1}}>$300,000</Typography>
                                </Box>
                            </Box>
                            <Ruler offsetLeft={35} vertical height={20} styles={{display: {xs: 'none', md: 'block'}}} />
                            <Box sx={{display: 'flex', alignItems: 'center'}}>

                                <Image width={75} src="/assets/images/value-icon.png" alt="value-icon"  />
                                <Box>
                                    <Typography variant="h6">Market Value</Typography>
                                    <Typography sx={{ml: 1}}>$350,000</Typography>
                                </Box>
                            </Box>
                            <Ruler offsetLeft={35} vertical height={20} styles={{display: {xs: 'none', md: 'block'}}} />
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Image width={75} src="/assets/images/renovation-icon.png" alt="renovation-icon" />
                                <Box>
                                    <Typography variant="h6">Reno Cost</Typography>
                                    <Typography sx={{ml: 1}}>$152,000</Typography>
                                </Box>
                            </Box>
                            <Ruler offsetLeft={35} vertical height={20} styles={{display: {xs: 'none', md: 'block'}}} />
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Image width={75} src="/assets/images/money-icon.png" alt="money-icon" />
                                <Box>
                                    <Typography variant="h6">Improved Value</Typography>
                                    <Typography sx={{ml: 1}}>$660,000</Typography>
                                </Box>
                            </Box>
                            <Ruler offsetLeft={35} vertical height={20} styles={{display: {xs: 'none', md: 'block'}}} />
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Image width={75} src="/assets/images/timeframe-icon.png" alt="timeframe-icon" />
                                <Box>
                                    <Typography variant="h6">Timeframe</Typography>
                                    <Typography sx={{ml: 1}}>10 Months</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default TheProof;
