import React from 'react';
import { Box, Link, Grid, List, ListItemText, ListItemButton, ListItemIcon, Container } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import Image from './core/Image';
import RemoveIcon from '@mui/icons-material/Remove';

const Footer = () => {
    return (
        <Box component="footer" sx={{bgcolor: 'secondary.main', p: 2, position: 'static', bottom: 0, right: 0, left: 0}}>
            <Container>
                <Grid container alignItems="flex-start">
                    <Grid item xs={12} md={4}>
                        <a href="/" style={{marginTop: 24, display: 'block'}}>
                            <Image src="/assets/images/alpha-returns-logo.png" alt="alpha-returns-logo" width={300} styles={{mx: {xs: 'auto', md: 0}}} />
                        </a>
                        <Box sx={{display: 'flex', alignItems: 'center', p: 2, mx: {xs: 'auto', md: 0}, width: 'fit-content'}}>    
                            <EmailIcon sx={{color: 'common.white', mr: 2, fontSize: 28}} />
                            <Link underline="none" variant="body2" color="common.white" href="mailto:info@alpha-returns.com.au">info@alpha-returns.com.au</Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <List>
                            <ListItemText primary="Quick Links" primaryTypographyProps={{variant: 'h6'}} />
                            <ListItemButton component="a" href="/">
                                <ListItemIcon sx={{minWidth: 'auto', mr: 1}}><RemoveIcon sx={{fontSize: 12, color: 'common.white'}} /></ListItemIcon>
                                <ListItemText primary="Home" primaryTypographyProps={{variant: 'body2', sx: {textTransform: 'uppercase'}}} />
                            </ListItemButton>       
                            <ListItemButton component="a" href="/about">
                                <ListItemIcon sx={{minWidth: 'auto', mr: 1}}><RemoveIcon sx={{fontSize: 12, color: 'common.white'}} /></ListItemIcon>
                                <ListItemText primary="About"primaryTypographyProps={{variant: 'body2', sx: {textTransform: 'uppercase'}}} />
                            </ListItemButton>
                            <ListItemButton component="a" href="/the-proof">
                                <ListItemIcon sx={{minWidth: 'auto', mr: 1}}><RemoveIcon sx={{fontSize: 12, color: 'common.white'}} /></ListItemIcon>
                                <ListItemText primary="The Proof"primaryTypographyProps={{variant: 'body2', sx: {textTransform: 'uppercase'}}} />
                            </ListItemButton>               
                            <ListItemButton component="a" href="/contact">
                                <ListItemIcon sx={{minWidth: 'auto', mr: 1}}><RemoveIcon sx={{fontSize: 12, color: 'common.white'}} /></ListItemIcon>
                                <ListItemText primary="Contact"primaryTypographyProps={{variant: 'body2', sx: {textTransform: 'uppercase'}}} />
                            </ListItemButton>                              
                        </List>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={3}>
                        <List>
                            <ListItemText primary="Connect With Us" primaryTypographyProps={{variant: 'h6'}} />
                            <ListItemButton component="a" href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                                <Image width={36} src="/assets/images/facebook.png" alt="facebook-icon" />
                            </ListItemButton>
                        </List>
                    </Grid> */}
                </Grid>
            </Container>
        </Box>
    );
}

export default Footer;
