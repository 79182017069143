import React from 'react';
import { Button, styled } from '@mui/material';
import propTypes from 'prop-types';

const Default = styled(Button)({
    borderRadius: '2.5px',
    fontWeight: 500,
    letterSpacing: 1,
    display: 'block',
    width: 'fit-content',
});

const Submit = styled(Button)({
    borderRadius: '2.5px',
    fontWeight: 500,
    letterSpacing: 1,
    display: 'block',
});

const StyledButton = ({loading, children, styles, primary = true, href, submit, setTriggerAction}) => {

    if(submit) return <Submit disabled={(loading) ? true : false} type="submit" fullWidth href={href} sx={styles} variant="contained" children={children} />
    if(primary) return <Default disabled={(loading) ? true : false} href={href} sx={styles} variant="contained" children={children} onClick={() => setTriggerAction(true)} />

    return <Default href={href} color="secondary" variant="contained" sx={styles} children={children} />
}

StyledButton.propTypes = {
    loading: propTypes.bool,
    submit: propTypes.bool,
    styles: propTypes.object,
    primary: propTypes.bool,
    href: propTypes.string,
    setTriggerAction: propTypes.oneOfType([propTypes.func, propTypes.bool]).isRequired,
}

export default StyledButton;
