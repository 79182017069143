import React from 'react';
import { styled } from '@mui/material';
import propTypes from 'prop-types';

const Default = styled('hr')(({theme}) => ({
    position: 'relative',
    border: 'none',
    borderTop: `solid 1px ${theme.palette.common.white}`,
    margin: 0
}));

const Vertical = styled('hr')(({theme}) => ({
    position: 'relative',
    border: 'none', 
    borderLeft: `solid 1px ${theme.palette.common.white}`,
    margin: 0,
    width: 'fit-content'
}));

const Ruler = ({height, width, offsetLeft, vertical = false, offsetRight, styles}) => {
    
    if(!vertical) return <Default sx={{width, left: (offsetLeft) ? offsetLeft : '', ...styles}} />
    
    return <Vertical sx={{height, left: (offsetLeft) ? offsetLeft : '', right: (offsetRight) ? offsetRight : '', ...styles}} /> 
}

Ruler.propTypes = {
    width: propTypes.oneOfType([propTypes.string, propTypes.number]),
    height: propTypes.oneOfType([propTypes.string, propTypes.number]),
    offsetLeft: propTypes.number,
    offsetRight: propTypes.number,
    vertical: propTypes.bool,
    styles: propTypes.object
}

export default Ruler;
