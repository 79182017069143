import React from 'react';
import { SvgIcon } from '@mui/material';

const ArrowIcon = () => {
    return (
        <SvgIcon fontSize="small" sx={{color: 'silver.primary'}}>
            <path d="M13.3045 0.195912C13.1176 0.273401 12.9956 0.454226 12.9956 0.654887V6.098L0.499848 6.098C0.22394 6.098 -1.19853e-08 6.32055 0 6.59474L3.47402e-07 14.5424C3.59388e-07 14.8166 0.223941 15.0391 0.499848 15.0391H12.9957L12.9957 20.5031C12.9957 20.7037 13.1176 20.8846 13.3036 20.9621C13.4905 21.0386 13.7054 20.9968 13.8484 20.8548L23.8531 10.9451C23.947 10.8517 24 10.7255 24 10.5934C24 10.4613 23.947 10.3351 23.854 10.2417L13.8494 0.303204C13.7064 0.161125 13.4915 0.118423 13.3045 0.195912Z"/>
        </SvgIcon>
    );
}

export default ArrowIcon;
