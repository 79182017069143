import React from 'react';
import { CustomCard } from '@tsamantanis/react-glassmorphism'
import propTypes from 'prop-types';
import { styled } from '@mui/material';

const Default = styled(CustomCard)({});

const Glass = ({children, blur = 10, styles}) => {
    return (
        <Default sx={{...styles, boxShadow: 4}} effectColor="#fff" borderRadius={5} blur={blur}>{children}</Default>
    );
}

Glass.propTypes = {
    children: propTypes.any,
    blur: propTypes.number,
    styles: propTypes.object,
}

export default Glass;
