import React, { useState } from 'react';
import { Box, Typography, styled } from '@mui/material';
import propTypes from 'prop-types';
import { validate } from '../../helpers/validate';

const Default = styled('textarea')(({theme}) => ({
    background: 'rgba(255,255,255,0.3)',
    color: theme.palette.common.white,
    padding: '0 16px',
    borderRadius: 5,
    width: '100%',
    height: 100,
    resize: 'vertical',
    maxHeight: 350,
    fontFamily: 'Arsenal',
    fontSize: 16,
    padding: '8px 16px',
}));

const TextArea = ({name, setFilled}) => {
    const id = name.toLowerCase();
    const [error, setError] = useState('');

    return (
        <>
            <Box sx={{display: 'flex'}}>
                <Typography sx={{textTransform: 'uppercase', minWidth: 80, mr: 1}} component="label" htmlFor={id}>{name}</Typography>
                <Default sx={{border: 2, borderColor: (error === '') ? 'rgba(0,0,0,0)' : 'error.main'}} onBlur={e => validate(e, id, setError, setFilled)} onChange={() => (error === '') ? setFilled(prev => ({...prev, [id]: true})) : ''} />            
            </Box>        
            <Typography sx={{my: 0, textTransform: 'uppercase', fontWeight: 700}} align="right" variant="body2" color="error.main">{error}</Typography>
        </>
    );
}

TextArea.propTypes = {
    name: propTypes.string.isRequired,
    setFilled: propTypes.func.isRequired,
}

export default TextArea;
