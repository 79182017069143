import React, { useState } from 'react';
import Input from './Input';
import { Stack, Alert, Typography } from '@mui/material';
import StyledButton from '../core/StyledButton';
import TextArea from './TextArea';
import { submitContact } from '../../helpers/submit';

const ContactForm = () => {
    const [filled, setFilled] = useState({
        name: false,
        email: false,
        message: false,
    });

    const [alertText, setAlertText] = useState(false);
    const [formError, setFormError] = useState(false);
    const [loading, setLoading] = useState(false);

    const submit = async e => {
        e.preventDefault();
        setLoading(true);

        const [name, phone, email, message] = e.target.elements;
            
        const fields = {
            name: name.value,
            phone: phone.value,
            email: email.value,
            message: message.value,
        };

        if(Object.values(filled).includes(false)) {
            // Error 
            setFormError(true);
            setAlertText('There was an issue with the data entered into the form. Please review the fields and try again.');
        } else {
            // Success
            const res = await submitContact(fields);
            
            console.log(res);
            setLoading(false);

            if(res.status == 200) {
                setFormError(false);
                setAlertText('Your details were successfully submitted. We will contact you shortly!');
            } else {
                setFormError(true);
                setAlertText('There has been an error processing your request, please try again later, or contact us via email.');
            }
            
        }
    }

    return (
        <form name="contact" onSubmit={e => submit(e)} method="post" data-netlify="true" action="/contact">
            <Typography variant="h5" align="center" sx={{mb: 2}} children="Contact Us" />
            <Stack>
                <Input setFilled={setFilled} name="Name" />
                <Input setFilled={setFilled} name="Phone" />
                <Input setFilled={setFilled} name="Email" />
                <TextArea setFilled={setFilled} name="Message" />
                <input type="hidden" name="form-name" value="contact" />
            </Stack>
            <StyledButton loading={loading} setTriggerAction={false} styles={{mt: 2}} submit>{(loading) ? 'Loading...' : 'Submit'}</StyledButton>
            <Alert sx={{my: 2, display: (!alertText) ? 'none' : 'flex'}} severity={(formError) ? 'error' : 'success'} children={alertText} />
        </form>            
    );
}

export default ContactForm;
